import React from "react"
import { navigate } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Illustration404 from "../images/404.png"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="bg-nav-primary min-h-screen">
      <div className="md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-screen-2xl max-w-screen-2xl mx-auto">
        <div className="flex flex-col xl:flex-row space-x-19 items-center pt-32 page404__bg-mask 2xl:pb-72 xl:pb-52">
          <div>
            <img src={Illustration404} alt="404 - Page not found" />
          </div>
          <div className="xl:mt-12 mt-20 w-404">
            <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-title-from to-title-to text-6xl-v0 font-black leading-12">404</h1>
            <h2 className="text-hero-title font-black text-6xl-v0 leading-13 my-6">Page not found</h2>
            <p className="text-hero-text leading-11 text-2xl font-normal"> The page you are looking for might have been removed, have its name changed or is temporarily unavailable.</p>
            <button onClick={() => navigate(-1)} className="mt-11 bg-button-purple text-white font-black text-xl-v0 px-7 py-5 rounded leading-5 tracking-wide">Get back</button>
          </div>
        </div>
      </div>
   
    </div>
    
  </Layout>
)

export default NotFoundPage
